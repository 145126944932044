<!--
 * @Author: your name
 * @Date: 2021-04-23 10:36:40
 * @LastEditTime: 2021-05-28 15:02:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/views/Production.vue
-->
<template>
	<div>
		<Header :baseInfo="baseInfo"/>
		<div class="swiper_banner" v-if="banner.length > 0" @mouseenter="on_swiper_enter" @mouseleave="on_swiper_leave">
			<swiper ref="mySwiperBanner" :options="swiperBanner" v-if="banner.length > 1">
				<swiper-slide v-for="(item , index) in banner" :key="index">
					<img style="width:100%;height:100%;" :src="item.pic" alt="">
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
			<img @click="adDetail(banner[0].url)" style="width:100%;" v-else :src="banner[0].pic" alt="">
		</div>
		<div class="before">
			<div class="production_tab">
				<div class="tab_title width_1200 margin_auto" :style="'background:url('+baseImgUrl+'juanzhou_bg.png);background-size:contain;background-repeat:no-repeat;'">
					<div style="width:900px;margin:0 auto;height:100%" class="d_flex justify_content_around align_items_center">
						<div @click="tabClick(index)" :class="index === tabActive ? 'active' : ''" v-for="(item, index) in titleList" :key="index">{{item}}</div>
					</div>
				</div>
				<div class="tab_items width_1200 margin_auto d_flex justify_content_between flex_wrap" v-for="(item , index) in productList" :key="index" v-show="index === tabActive ? true : false">
					<div class="tab_item" v-for="(i , idx) in item" :key="idx">
						<el-image style="width:100%;height:100%;" :src="baseImgUrl + i" :preview-src-list="srcList[index]"></el-image>
						<img class="blow_up" :src="baseImgUrl + 'blow_up.png'" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League :baseInfo="baseInfo" @openPop="openPop()"/>
			</div>
		</div>
		<div ref="footer_container">
			<Footer :baseInfo="baseInfo"/>
		</div>
		<Form v-show="isShow" @closePop="closePop()" />
		<Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>
	</div>
</template>

<script>
import {config} from '../config';
import mixin from '../utils/mixin';
export default {
	mixins:[mixin],
	data () {
		return {
			baseImgUrl:config.baseImgUrl,
			tabActive:0,
			titleList:['学生作品', '老师作品' , '书法作品'],
			productList:[
				['pro_11.png' , 'pro_12.png' , 'pro_13.png' , 'pro_14.png' , 'pro_15.png' , 'pro_16.png'],
				['pro_17.png' , 'pro_18.png' , 'pro_19.png' , 'pro_20.png' , 'pro_21.png' , 'pro_22.png'],
				['pro_23.png' , 'pro_24.png' , 'pro_25.png' , 'pro_26.png' , 'pro_27.png' , 'pro_28.png']
			],
			srcList:[]
		};
	},
	components:{
		"Form":()=>import('../components/Form')
	},

	created(){
		let arr = [];
		this.productList.forEach((e)=>{
			let arr2 = []
			e.forEach(f=>{
				f = this.baseImgUrl+f
				arr2.push(f);
			})
			arr.push(arr2);
		});
		this.srcList = arr;
	},

	watch:{
		$route:{
			immediate:true,
			handler(newval){
				let type = newval.query.type*1
				this.tabActive = type === 1 ? 0 : type === 2 ? 1 : type === 3 ? 2 : 0
			}
		}
	},

   	methods: {

		tabClick(index){
			this.tabActive = index
		}
	}
}

</script>
<style lang='scss' scoped>
	.production_banner{
		width:100%;
	}
	.production_tab{
		margin-top:90px;
		margin-bottom: 70px;
		.tab_title{
			height:80px;
			div{
				width: 180px;
				height:50px;
				line-height: 50px;
				text-align: center;
				font-size: 24px;
				color:#fff;
				background: transparent;
				border-radius: 25px;
				cursor: pointer;
			}
			div.active{
				background: #B09475;
				font-weight: bold;
			}
		}
		.tab_items{
			margin-top:30px;
			.tab_item{
				width: 360px;
				height: 270px;
				border:4px solid #68838C;
				position: relative;
				margin-top:50px;
				cursor: pointer;
				overflow: hidden;
				.el-image{
					transition: all 1s ease 0s;
					transform: scale(1);
				}
				.blow_up{
					position: absolute;
					top:0;
					right:0;
					width:64px;
					height:64px;
				}
			}
			.tab_item:hover{
				.el-image{
					transform: scale(1.1);
				}
			}
		}
	}
</style>